import T from 'i18n';
import _ from 'lodash';
export const deleteItemImmutable = (items = [], index = -1, quantity = 1) => {
  if (index > items.length - 1 || index < 0) throw new Error('Index out of bounds');
  if (quantity < 1 || quantity + index > items.length) throw new Error('Quantity out of bounds');
  return [
    ...((index > 0 && items.slice(0, index)) || []),
    ...((index < items.length && items.slice(Math.min(index + quantity, items.length))) || []),
  ];
};

export const deleteItemImmutableDeepNested = (
  items = [],
  keyValue,
  key = 'id',
  subFolder = 'folders',
) => {
  return items.reduce((acc, item) => {
    if (item[key] === keyValue) return acc;
    if (item[subFolder])
      item[subFolder] = deleteItemImmutableDeepNested(item[subFolder], keyValue, key, subFolder);
    return acc.concat(item);
  }, []);
};

export const replaceItemImmutable = (items, item, index = -1) => {
  if (index > items.length - 1 || index < 0) throw new Error('Index out of bounds');
  return [
    ...((index > 0 && items.slice(0, index)) || []),
    item,
    ...(index < items.length && items.slice(index + 1)),
  ];
};

export const mergeItemImmutable = (items, item, index = -1) => {
  if (index > items.length - 1 || index < 0) throw new Error('Index out of bounds');
  return [
    ...((index > 0 && items.slice(0, index)) || []),
    { ...items[index], ...item },
    ...(index < items.length && items.slice(index + 1)),
  ];
};

export const replaceItemImmutableDeepNested = (
  items = [],
  item,
  key = 'id',
  subFolder = 'folders',
) => {
  return items.map(anItem => {
    if (anItem[key] === item[key]) {
      return item; // Replace with new item
    }
    // Check if subFolder exists and is an array
    if (Array.isArray(anItem[subFolder])) {
      return {
        ...anItem,
        [subFolder]: replaceItemImmutableDeepNested(anItem[subFolder], item, key, subFolder),
      };
    }
    return anItem; // Return existing item unchanged
  });
};

export const insertItemImmutable = (items, item, index) => {
  if (index > items.length || index < 0) throw new Error('Index out of bounds');
  return [
    ...((index > 0 && items.slice(0, index)) || []),
    item,
    ...((index < items.length && items.slice(index)) || []),
  ];
};

export const moveItemImmutable = (items, item, indexFrom = -1, indexTo = -1) => {
  return insertItemImmutable(deleteItemImmutable(items, indexFrom), item, indexTo);
};

export const addItemImmutableDeepNested = (
  items,
  item,
  parentKey = 'parentFolderId',
  key = 'id',
  subFolder = 'folders',
) => {
  if (!item[parentKey]) return addItemImmutable(items, item);

  return items.reduce((acc, anItem) => {
    if (anItem[key] === item[parentKey])
      // add new item to children collection
      anItem[subFolder] = addItemImmutable(anItem[subFolder], item);
    else if (anItem[subFolder])
      anItem[subFolder] = addItemImmutableDeepNested(
        anItem[subFolder],
        item,
        parentKey,
        key,
        subFolder,
      );

    return acc.concat(anItem);
  }, []);
};

export const addItemImmutable = (items = [], item) => {
  return [...items, item];
};

export const addItemImmutableAtFirst = (items = [], item) => {
  return [item, ...items];
};

export function findLast(array, predicate) {
  for (let i = array.length - 1; i >= 0; --i) {
    const x = array[i];
    if (predicate(x)) {
      return x;
    }
  }
}

export function findLastIndex(array, predicate) {
  for (let i = array.length - 1; i >= 0; --i) {
    const x = array[i];
    if (predicate(x)) {
      return i;
    }
  }
}

// This function is used to find the depth of a folder at any level
export function findFolderDepth(folders, targetFolderId, depth = 0) {
  let currentDepth = -1;
  _.forEach(folders, parentFolder => {
    if (parentFolder.folders) {
      const found = _.find(parentFolder.folders, { id: targetFolderId });

      if (found) {
        currentDepth = depth + 1;
        return false;
      }

      const nestedDepth = findFolderDepth(parentFolder.folders, targetFolderId, depth + 1);

      if (nestedDepth > -1) {
        currentDepth = nestedDepth;
        return false;
      }
    }
  });
  return currentDepth;
}
// This function is used to find the index of a folder in a nested folder structure
export function findIndexOfDeepNestedFolder(
  folders = [],
  folder,
  key = 'id',
  subFolder = 'folders',
) {
  let index = -1;
  for (let i = 0; i <= folders.length - 1; i++) {
    if (folders[i][key] === folder[key]) {
      return i;
    }
    if (folders[i][subFolder].length > 0) {
      index = findIndexOfDeepNestedFolder(folders[i][subFolder], folder);
      if (index !== -1) return index;
    }
  }
  return index !== -1 ? index : -1;
}

export function partition(array, callback) {
  return array.reduce(
    function(result, element, i) {
      callback(element, i, array) ? result[0].push(element) : result[1].push(element);

      return result;
    },
    [[], []],
  );
}

export const complexValuesToOptions = (options, prefix) =>
  options.map(obj => ({
    label: obj.label ? obj.label : T.translate(`${prefix}.${obj.value}`),
    value: obj.value,
    isFixed: obj.isFixed,
  }));

export const simpleValuesToOptions = (options, prefix) =>
  options.map(value => ({ label: T.translate(`${prefix}.${value}`), value }));

export const createListofRecentItems = (item, array) => {
  //support 5 items
  if (array.includes(item)) {
    return [item, ...array.filter(itm => itm !== item)];
  } else {
    if (array.length > 4) array.pop();
    return [item, ...array];
  }
};
