import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ReactThreeToggle from 'react-three-toggle';

type ThreeStateToggleProps = {
  defaultValue: string;
  values: Array<string>;
  label1: string;
  label2: string;
  label3: string;
  onChanged: (value: string) => void;
  style?: React.CSSProperties;
};

export default function ThreeStateToggle({
  defaultValue,
  values,
  label1,
  label2,
  label3,
  onChanged,
  style,
}: ThreeStateToggleProps) {
  const [value, setValue] = React.useState(defaultValue || values[0]);

  if (values.length !== 3) throw new Error('ThreeStateToggle requires exactly 3 values');

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 0.2rem',
        ...style,
      }}
    >
      <Typography style={{ marginLeft: '0', marginTop: '0.5rem' }}>
        <Box component="span" style={{ color: '#FFB5B1' }} fontWeight="bold">
          {label1}
        </Box>
        {' | '}
        <Box component="span" fontWeight="bold">
          {label2}
        </Box>
        {' | '}
        <Box component="span" style={{ color: '#95BAE8' }} fontWeight="bold">
          {label3}
        </Box>
      </Typography>
      <ReactThreeToggle
        onChange={(next: any) => {
          if (next === value) return;
          setValue(next);
          onChanged(next);
        }}
        style={{
          selectedStyle: {
            background: value === values[2] ? '#7AA0F4' : value === values[0] ? '#D86B57' : 'white',
            width: '1.4rem',
            height: '1.4rem',
            marginTop: '-0.5rem',
            marginLeft: '-0.5rem',
            boxShadow: '0px 1px 2px 0px #9E9E9E',
          },
          wrapperStyle: {
            background:
              value === values[2] ? '#95BAE8' : value === values[0] ? '#FFB5B1' : '#9E9E9E',
            margin: '0.5rem',
            width: '2em',
            border: 'none',
            borderRadius: '0.5rem',
            padding: '3px',
          },
        }}
        height={5}
        values={values}
        initialValue={value}
      />
    </div>
  );
}
