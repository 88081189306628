export const documentModalActions = {
  copy: 'COPY',
  remove: 'REMOVE',
  update: 'UPDATE',
  updateMeta: 'UPDATE META',
  merge: 'MERGE',
  replace: 'REPLACE',
  generateHyperlinks: 'GENERATE HYPERLINKS',
  generateTranscriptsbasedHyperlinks: 'GENERATE TRANSCRIPT HYPERLINKS',
  findAndReplaceHyperlinks: 'FIND AND REPLACE HYPERLINKS',
  moreInfo: 'MORE INFO',
  fileVersions: 'FILE VERSIONS',
  download: 'DOWNLOAD',
  fileVariants: 'FILE VARIANTS',
  uploadTranscriptNotes: 'UPLOAD TRANSCRIPT NOTES',
  changePosition: 'CHANGE POSITION',
};

export const folderModalActions = {
  moveToTrialBooks: 'MOVE TO TRIALBOOKS',
  removeFromTrialBooks: 'REMOVE FROM TRIALBOOKS',
  moveToCourtBundles: 'MOVE',
  removeFromCourtBundles: 'REMOVE',
  createTeamBundle: 'CREATE TEAM BUNDLE',
  createPrivateBundle: 'CREATE PRIVATE BUNDLE',
  updateBundle: 'UPDATE',
  createBundle: 'ADD',
  deleteBundle: 'DELETE',
  mergeBundle: 'MERGE',
  createTrialbook: 'ADD TRIALBOOK',
  agreeTrialbook: 'AGREE',
  mergeTrialbook: 'MERGE TRIALBOOK',
  updateTrialbook: 'UPDATE TRIALBOOK',
  deleteTrialbook: 'DELETE TRIALBOOK',
  showHistory: 'SHOW HISTORY',
  refresh: 'REFRESH',
  createTag: 'CREATE TAG',
  updateTag: 'UPDATE TAG',
  deleteTag: 'DELETE TAG',
  burnCrossRef: 'BURN CROSS REF',
  reIndex: 'RE INDEX',
  healthCheck: 'HEALTH CHECK',
  createTimeline: 'CREATE TIMELINE',
  updateTimeline: 'UPDATE TIMELINE',
  deleteTimeline: 'DELETE TIMELINE',
  exportTimeline: 'EXPORT TIMELINE',
  exportFolder: 'EXPORT FOLDER',
  downloadFolder: 'DOWNLOAD FOLDER',
  deleteAll: 'DELETE ALL',
  clone: 'CLONE',
  convertToBundle: 'CONVERT TO BUNDLE',
};

export const fileTypes = {
  mp4: 'mp4',
};

export const teamAdminModal = {
  addMember: 'ADD MEMBER',
  removeMember: 'REMOVE MEMBER',
  inviteMember: 'INVITE MEMBER',
  updateMember: 'UPDATE MEMBER',
  getInviteLink: 'GET INVITE LINK',
  getActivityLogs: 'GET ACTIVITY LOGS',
  changeHearingRoomPosition: 'CHANGE HEARING ROOM POSITION',
  systemTestResult: 'SYSTEM TEST RESULT',
  removeSystemTestResult: 'REMOVE SYSTEM TEST RESULT',
  reSendInvite: 'RESEND INVITE',
  changeTeam: 'CHANGE TEAM',
  loginHistory: 'LOGIN HISTORY',
  bulkUpload: 'BULK UPLOAD',
};

export const caseAdminModalActions = {
  addNewTeam: 'ADD NEW TEAM',
  updateTeamName: 'UPDATE TEAM NAME',
  removeTeam: 'REMOVE TEAM',
};

export const caseRolesModalAction = {
  addNewMember: 'ADD NEW MEMBER',
  removeMember: 'REMOVE MEMBER',
};

export const annotationsTableAction = {
  update: 'UPDATE',
  delete: 'DELETE',
  deleteAnnotaion: 'DELETE ANNOTATION',
  addNewEvent: 'ADD NEW EVENT',
};

export const confidentialityRingCategoryLabel = {
  innerRing: 'Inner Ring',
  outerRing: 'Outer Ring',
  noRing: 'No Ring',
};

export const suggestedRecipientsMenuOptions = {
  addAll: 'ADD ALL',
};
