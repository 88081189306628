import React from 'react';
import { isValidKey } from 'utils/keyboard';
import TextForm from '../TextForm';

export default React.forwardRef(
  ({ value, errors, autofocus, label, placeholder, ...props }: any, ref: any) => (
    <TextForm
      {...props}
      ref={ref}
      label={label}
      placeholder={placeholder}
      value={value}
      onKeyDown={(e: any) => isValidKey(e)}
      errors={errors}
      autofocus={autofocus}
    />
  ),
);
