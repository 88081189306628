import React, { useEffect, useMemo } from 'react';
import T from 'i18n';
import { Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, DateRangeIcon, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse, isValid } from 'date-fns';
import logger from 'utils/logger';
import ThreeStateToggle from 'features/common/Filters/ThreeStateToggle';
import LookupMui from 'features/common/MaterialBasedComponents/Lookup';
import { useDispatch } from 'react-redux';
import { fetchAuthorsLookup } from 'features/viewing/redux/fetchAuthorsLookup';
import { fetchRecipientsLookup } from 'features/viewing/redux/fetchRecipientsLookup';
import { fetchCreatedByLookup } from 'features/viewing/redux/fetchCreatedByLookup';
import debounce from 'utils/debounce';
import { fetchDocumentsDocFinder } from '../redux/fetchDocumentsDocFinder';
import { useSelector } from 'react-redux';
import { selectFolderContainers } from 'common/selectors';
import FilterOption from 'features/common/Filters/FilterOption';
import { makeStyles } from '@mui/styles';
import DateInput from 'features/common/FormControls/DateInput';

const useStyles = makeStyles({
  root: {
    '& .MuiGrid-item': {
      paddingTop: '0 !important',
    },

    '& .MuiInput-input ': {
      padding: '4px 4px 4px 0px !important',
    },
  },
});

const LookupWithLabel = React.forwardRef(({ label, fetch, ...rest }: any, ref: any) => {
  return (
    <>
      <Typography
        style={{
          marginLeft: 0,
          alignSelf: 'center',
          marginTop: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        {label}
      </Typography>
      <LookupMui
        {...rest}
        fetchFunction={fetch}
        minInputLength={2}
        variant="standard"
        multiple
        inline
      />
    </>
  );
});

export default () => {
  const {
    watch,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      search: '',
      dateFrom: null,
      dateTo: null,
      author: undefined,
      recipient: undefined,
      trialBook: undefined,
      createdBy: undefined,
      hasAnnotations: 'null',
    },
  });

  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = debounce((data: any) => dispatch(fetchDocumentsDocFinder(data)), 250);

  const handleUserTypedDate = (field: any) => (event: any) => {
    const typedDate = event.target.value;
    const parsedDate = parse(typedDate, 'dd/MM/yyyy', new Date());
    if (isValid(parsedDate)) {
      // handleDateChange(field)(parsedDate);
    } else {
      logger.ERROR('Invalid date format.');
    }
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  const folderContainers = useSelector(selectFolderContainers);

  const trialBundles = useMemo(() => {
    const reduceTrialBundleFolders = (folders: any) =>
      folders
        .reduce(
          (acc: any, item: any) =>
            ([] as any).concat(
              acc,
              !(item.name === 'index' && item.readOnly)
                ? {
                    name: item.name,
                    id: item.id,
                    level: item.level,
                    group: Array.isArray(item.folders) && item.folders.length > 0,
                  }
                : [],
              Array.isArray(item.folders) && item.folders.length > 0
                ? reduceTrialBundleFolders(item.folders)
                : [],
            ),
          [],
        )
        .filter(Boolean);
    const trialbooks = folderContainers.find((itm: { id: string }) => itm.id === 'trialbooks');

    const trialBundlesMemo = trialbooks?.folders || [];
    return reduceTrialBundleFolders(trialBundlesMemo);
  }, [folderContainers]);

  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={12}>
        <Typography
          style={{
            marginLeft: 0,
            alignSelf: 'center',
            marginTop: '0.5rem',
            fontWeight: 'bold',
          }}
        >
          {T.translate('case.search')}
        </Typography>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              style={{ width: '100%' }}
              placeholder="Look up by file name, Doc ID, Global Page or other meta data"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography
            style={{
              marginLeft: 0,
              alignSelf: 'center',
              marginTop: '0.5rem',
              fontWeight: 'bold',
            }}
          >
            {T.translate('filters.dateFromTo')}
          </Typography>
          <div style={{ display: 'flex' }}>
            <Controller
              name="dateFrom"
              control={control}
              render={({ field }) => (
                <DateInput
                  {...field}
                  placeholder={T.translate('generic.from')}
                  variant="standard"
                />
              )}
            />
            <Controller
              name="dateTo"
              control={control}
              render={({ field }) => (
                <DateInput
                  {...field}
                  placeholder={T.translate('generic.to')}
                  style={{ marginLeft: '1rem' }}
                  variant="standard"
                />
              )}
            />
          </div>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="author"
          control={control}
          render={({ field }) => {
            return (
              <LookupWithLabel
                {...field}
                fetch={fetchAuthorsLookup}
                label={T.translate('filters.authors')}
                placeholder="Search by author's name"
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="recipient"
          control={control}
          render={({ field }) => {
            return (
              <LookupWithLabel
                {...field}
                fetch={fetchRecipientsLookup}
                label={T.translate('filters.recipients')}
                placeholder="Search by recipient's name"
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="trialBook"
          control={control}
          render={({ field }) => {
            return (
              <FilterOption
                {...field}
                originalArray={trialBundles}
                filterName={'Trial Bundles'}
                filterKey={'trialBundles'}
                valKey="id"
                labelKey="name"
                inline
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="createdBy"
          control={control}
          render={({ field }) => {
            return (
              <LookupWithLabel
                {...field}
                fetch={fetchCreatedByLookup}
                label={T.translate('filters.createdBy')}
                placeholder="Search by created by's name"
              />
            );
          }}
        />
      </Grid>
      {/* <Divider style={{ width: '100%' }} /> */}
      <Grid item xs={12}>
        <Controller
          name="hasAnnotations"
          control={control}
          render={({ field }) => {
            return (
              <ThreeStateToggle
                // {...field}
                label1={T.translate('filters.withoutAnnotations')}
                label2={T.translate('filters.allDocuments')}
                label3={T.translate('filters.withAnnotations')}
                style={{ padding: 0 }}
                values={['false', 'null', 'true']}
                onChanged={field.onChange}
                defaultValue={field.value}
              />
            );
          }}
        />
      </Grid>
      {/*   <Grid item xs={12}>
        <ThreeStateToggle
          label1={T.translate('filters.withoutPublicHyperlinks')}
          label2={T.translate('filters.withAllHyperlinks')}
          label3={T.translate('filters.withPublicHyperlinks')}
          style={{ padding: 0 }}
          defaultValue={
            filterCategory?.withoutPublicHyperlinks
              ? 'withoutPublicHyperlinks'
              : filterCategory?.withPublicHyperlinks
              ? 'withPublicHyperlinks'
              : 'null'
          }
          values={['withoutPublicHyperlinks', 'null', 'withPublicHyperlinks']}
          onChanged={value =>
            handleThreeStateToggle(value, ['withPublicHyperlinks', 'withoutPublicHyperlinks'])
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ThreeStateToggle
          label1={T.translate('filters.privateOnly')}
          label2={T.translate('filters.allAnnotations')}
          label3={T.translate('filters.publicOnly')}
          style={{ padding: 0 }}
          defaultValue={
            filterCategory?.private ? 'private' : filterCategory?.public ? 'public' : 'null'
          }
          values={['private', 'null', 'public']}
          onChanged={value => handleThreeStateToggle(value, ['private', 'public'])}
        />
      </Grid> */}
    </Grid>
  );
};
