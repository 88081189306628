import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Modifier from 'features/common/Modifier';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useRef, useState, useEffect } from 'react';
import { selectCurrentFolder, selectTruncateLongFolderName } from 'common/selectors';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';

export default ({ folder, icon, buttonActions }: any) => {
  const titleRef = useRef<any>();
  const [showTooltip, setShowTooltip] = useState(false);
  const [folderSelected, setFolderSelected] = useState(false);
  const truncateLongFolderName = useSelector(selectTruncateLongFolderName);
  const currentFolder = useSelector(selectCurrentFolder) as any;

  useEffect(() => {
    setShowTooltip(
      titleRef.current !== null &&
        !folderSelected &&
        titleRef.current?.clientHeight < titleRef.current?.scrollHeight,
    );
    if (folderSelected && currentFolder.code !== folder.code) setFolderSelected(false);
    else if (!folderSelected && currentFolder.code === folder.code) setFolderSelected(true);
  }, [folderSelected, setShowTooltip, currentFolder, folder]);
  return (
    <>
      <div style={{ flexGrow: 1 }}>
        {icon && <FontAwesomeIcon icon={icon} />}
        <OverlayTrigger
          placement="top"
          delay={{ show: 100, hide: 100 }}
          trigger={showTooltip ? ['hover', 'focus', 'click'] : []}
          overlay={
            <Popover id="folderName" style={{ pointerEvents: 'none' }}>
              <Popover.Content>{folder.name}</Popover.Content>
            </Popover>
          }
        >
          <div
            ref={titleRef}
            className={classnames({
              longFolderName: truncateLongFolderName && !folderSelected,
            })}
          >
            <span style={{ flexDirection: 'row' }}>
              {folder.name}

              {folder?.level === 0 && folder?.agreed && (
                <FontAwesomeIcon
                  style={{
                    color: '#E49C47',
                    height: '10px',
                    width: '10px',
                    position: 'absolute',
                    marginLeft: 3,
                  }}
                  icon={faLock}
                  // transform="shrink-1"
                />
              )}
            </span>
          </div>
        </OverlayTrigger>
      </div>
      {buttonActions && <Modifier {...{ buttonActions: buttonActions({ folder }) }} />}
    </>
  );
};
