import React from 'react';
import T from 'i18n';
import { ExpandLess, ExpandMore, FilterListOutlined, Group, Info } from '@mui/icons-material';
import { Avatar, Badge, Button, Chip, Collapse, Grid, Tooltip, Typography } from '@mui/material';
import { SearchOptionsTooltip } from './SearchOptionsTooltip';
import { makeStyles } from '@mui/styles';
import MatchesContainer from './MatchesContainer';
import SuggestedQuestionsCard from './SuggestedQuestionsCard';

const useStyles = makeStyles({
  matchesDisplayContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem 1rem 1rem 0',
  },
  matchesDisplayContainerAvatar: {
    width: 36,
    height: 36,
  },
  matchesDisplayContainerTopicSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  matchesDisplayContainerTopicText: {
    color: '#777',
    fontWeight: 700,
    marginRight: '1rem',
  },
  matchesResponseSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    padding: '1rem 1rem 1rem 0',
  },
  matchesResponseSectionAvatar: {
    width: 36,
    height: 36,
    marginRight: '1rem',
    backgroundColor: '#85A0F1',
  },
  matchesResponseSectionText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  matchesResponseSectionChip: {
    borderColor: '#85A0F1',
    marginLeft: '1rem',
    marginBottom: '0.5rem',
    fontSize: '0.9rem',
  },
});

const searchingTextStyle = {
  animation: `blinker 1s linear infinite`,
  fontWeight: 600,
  color: '#0000007a',
};

const SearchMatchesDisplayContainer = ({
  matchesData,
  fetchDocumentsPending,
  startNewSearch,
  showMatches,
  handleToggleMatches,
  selectedMatches,
  handleSelectMatches,
  setSelectedContainerId,
  suggestedQuestions,
  processQuestions,
}: any) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <div className={classes.matchesDisplayContainer}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              matchesData.contributor ? (
                <Tooltip title={matchesData.contributor} placement="top" arrow>
                  <Avatar sx={{ width: 16, height: 16, bgcolor: 'white' }}>
                    <Group sx={{ color: 'GrayText' }} fontSize="small" />
                  </Avatar>
                </Tooltip>
              ) : null
            }
          >
            <Avatar className={classes.matchesDisplayContainerAvatar}>
              <Typography>{matchesData.user}</Typography>
            </Avatar>
          </Badge>
          <div style={{ marginLeft: '1rem' }}>
            <div className={classes.matchesDisplayContainerTopicSection}>
              <Typography
                variant="h4"
                className={classes.matchesDisplayContainerTopicText}
              >{`${matchesData.phrase}`}</Typography>
              {matchesData.options && (
                <Tooltip
                  title={<SearchOptionsTooltip options={matchesData.options} />}
                  placement="right"
                  arrow
                >
                  <Info fontSize="small" sx={{ color: '#85A0F1' }} />
                </Tooltip>
              )}
            </div>
            {((matchesData.location && matchesData.location.length > 0) ||
              (matchesData.files && matchesData.files.length > 0)) && (
              <Tooltip
                placement="top"
                title={
                  <>
                    {matchesData.location.length > 0 && (
                      <span>
                        <b>{T.translate('case.folders')}</b> :{' '}
                        {matchesData.location.map((loc: any) => loc.name).join(', ')}{' '}
                      </span>
                    )}
                    {matchesData.files.length > 0 && (
                      <span>
                        <b>{T.translate('case.files')}</b> :{' '}
                        {matchesData.files.map((bm: any) => bm.name).join(', ')}
                      </span>
                    )}
                  </>
                }
                arrow
              >
                <div style={{ marginTop: '0.5rem' }}>
                  <FilterListOutlined fontSize="small" sx={{ mr: '0.5rem', mb: '0.2rem' }} />
                  {matchesData.location.length > 0 && (
                    <span>
                      <b>{T.translate('case.folders')}</b> : {matchesData.location[0].name}
                      {matchesData.location.length > 1
                        ? ` & ${matchesData.location.length - 1} more ...`
                        : ''}{' '}
                    </span>
                  )}
                  {matchesData.files && matchesData.files.length > 0 && (
                    <span>
                      <b>{T.translate('case.files')}</b> : {matchesData.files[0].name}
                      {matchesData.files.length > 1
                        ? ` & ${matchesData.files.length - 1} more ...`
                        : ''}
                    </span>
                  )}
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </Grid>
      {(matchesData.matches || fetchDocumentsPending) && (
        <>
          <Grid item xs={12}>
            <div className={classes.matchesResponseSection}>
              <Avatar className={classes.matchesResponseSectionAvatar}>
                <Typography>{T.translate('case.tvAvatarText')}</Typography>
              </Avatar>
              {fetchDocumentsPending && (
                <div>
                  <Typography sx={{ fontWeight: 600, color: '#0000007a' }}>
                    {T.translate('case.trialview')}
                  </Typography>
                  <Typography style={searchingTextStyle}>
                    {T.translate('case.searching')}
                  </Typography>
                </div>
              )}
              {matchesData.matches && !fetchDocumentsPending && (
                <div className={classes.matchesResponseSectionText}>
                  <Typography
                    variant={matchesData.matches.length > 0 ? 'h5' : 'h6'}
                    sx={{
                      ...(matchesData.matches.length > 0 && {
                        fontWeight: 600,
                        color: '#0000007a',
                      }),
                    }}
                  >
                    {matchesData.matches.length > 0
                      ? T.translate('case.matchesFoundMsg', {
                          matchesCount: matchesData.matches.length,
                        })
                      : T.translate('case.noMatchesFoundMsg')}
                  </Typography>
                  {matchesData.matches.length > 0 ? (
                    <Chip
                      label={
                        showMatches
                          ? T.translate('case.matchesChipLabel')
                          : T.translate('case.allMatchesChipLabel')
                      }
                      color="primary"
                      variant="outlined"
                      size="medium"
                      className={classes.matchesResponseSectionChip}
                      clickable
                      icon={showMatches ? <ExpandLess /> : <ExpandMore />}
                      onClick={() => handleToggleMatches()}
                    />
                  ) : (
                    <Button
                      size="large"
                      variant="text"
                      color="primary"
                      onClick={() => startNewSearch()}
                    >
                      {T.translate('case.newSearch')}
                    </Button>
                  )}
                </div>
              )}
            </div>
            {matchesData.matches && (
              <div>
                {!showMatches && (
                  <MatchesContainer
                    matches={matchesData.matches.slice(0, 5)}
                    handleSelectMatches={handleSelectMatches}
                    selectedMatches={selectedMatches}
                    setSelectedContainerId={setSelectedContainerId}
                    previewMatchesMode={true}
                  />
                )}
                <Collapse in={showMatches} timeout={{ exit: 0 }}>
                  <MatchesContainer
                    matches={matchesData.matches}
                    handleSelectMatches={handleSelectMatches}
                    selectedMatches={selectedMatches}
                    setSelectedContainerId={setSelectedContainerId}
                  />
                </Collapse>
              </div>
            )}
          </Grid>
          {suggestedQuestions && (
            <Grid item xs={12}>
              <Typography
                variant="h5"
                className={classes.matchesDisplayContainerTopicText}
                sx={{ mt: '1.5rem' }}
              >
                {T.translate('case.suggestedQuestions')}
              </Typography>
              {suggestedQuestions.searching ? (
                <Typography style={searchingTextStyle}>{T.translate('case.searching')}</Typography>
              ) : suggestedQuestions.questions ? (
                <SuggestedQuestionsCard
                  response={suggestedQuestions.questions}
                  processQuestions={processQuestions}
                />
              ) : (
                <Typography>{T.translate('case.noSuggestedQuestions')}</Typography>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default SearchMatchesDisplayContainer;
